import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '/'
    },
    {
      title: 'Privacy Policy',
      href: '/'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Automated Report',
      href: '/service/service-details'
    },
    {
      title: 'Custom Report Templates',
      href: '/service/service-details'
    },
    {
      title: 'Advanced Plagiarism Detection',
      href: '/service/service-details'
    },
    {
      title: 'PDF Generation',
      href: '/service/service-details'
    },
  ]

  return (
    <footer className="cs-fooer" style={{textAlign:"center"}}>
      <Div className="cs-fooer_main">
        <Div className="container">
           <Div className="cs-footer_item">
                <ContactInfoWidget title='VoiceBreeze'/>
              </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 voicebreeze.io</Div>
          </Div>
          {/* <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div> */}
        </Div>
      </Div>
    </footer>
  )
}
