import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
     {
      question: 'What is VoiceBreeze?',
      answer: 'VoiceBreeze is an advanced Voice AI solution designed to automate and enhance customer service interactions in call centers. It uses state-of-the-art conversational intelligence to provide seamless, efficient, and human-like conversations with customers.'
    },
    {
      question: 'How does VoiceBreeze work?',
      answer: 'VoiceBreeze integrates with your existing call center infrastructure and uses AI to handle customer interactions. It can understand and respond to queries in real-time, provide personalized support, and even route calls to the appropriate department when necessary. Say goodbye to long wait times—VoiceBreeze gets your customers the help they need, faster.'
    },
    {
      question: 'What industries can benefit from VoiceBreeze?',
      answer: 'VoiceBreeze is versatile and can be applied across various industries, including Banking and Financial Services, Telecommunications, Healthcare, Retail and E-commerce, Travel and Hospitality, and Utilities. It’s designed to meet the specific needs of each sector, enhancing customer experience and operational efficiency.'
    },
    {
      question: 'How does VoiceBreeze handle complex customer queries?',
      answer: 'VoiceBreeze is equipped with advanced conversational intelligence that understands context and can manage complex queries. For situations requiring human intervention, it seamlessly routes the call to the appropriate representative or department. No more endless transfers—VoiceBreeze ensures your customers reach the right person the first time.'
    },
    {
      question: 'Can VoiceBreeze be customized for my business?',
      answer: "Absolutely! VoiceBreeze is fully customizable to match your brand's voice and meet your specific needs. Our team works with you to train the AI using your unique data, ensuring it understands the nuances of your industry and customer base."
    },
    {
      question: 'How does VoiceBreeze improve customer service efficiency?',
      answer: 'By automating routine tasks like answering frequently asked questions, processing payments, and scheduling appointments, VoiceBreeze reduces response times and frees up your human agents to focus on more complex issues. This leads to faster resolutions and higher customer satisfaction. Resolve customer issues in record time with VoiceBreeze’s smart automation.'
    },
    {
      question: "Is VoiceBreeze available 24/7?",
      answer:"Yes, VoiceBreeze operates around the clock, ensuring your customers have access to support at any time of day or night. This continuous availability helps improve customer loyalty and trust in your brand. No more after-hours frustration—VoiceBreeze is always on call for your customers."
    },
     {
      question: "What kind of reporting and analytics does VoiceBreeze offer?",
      answer:"VoiceBreeze provides a real-time reporting dashboard that tracks key performance indicators, customer satisfaction scores, and conversation trends. These insights allow you to make data-driven decisions and continuously optimize your customer service strategy. Stay ahead with real-time insights that drive smarter decisions."
    },
     {
      question: "How does VoiceBreeze handle data security and privacy?",
      answer:"VoiceBreeze is built with robust security measures to protect customer data. We adhere to industry best practices and compliance standards, ensuring that all interactions and data are handled securely."
    },
     {
      question: "How long does it take to deploy VoiceBreeze?",
      answer:"The deployment time for VoiceBreeze varies depending on the complexity of your call center operations and customization needs. However, our team works efficiently to integrate and launch the solution as quickly as possible, with minimal disruption to your operations."
    },
     {
      question: "Can VoiceBreeze scale with my business?",
      answer:"Yes, VoiceBreeze is designed to scale with your business. Whether you experience a surge in call volume, launch new products, or expand into new markets, VoiceBreeze can adapt to meet your growing needs."
    },
     {
      question: "How does VoiceBreeze keep improving over time?",
      answer:"VoiceBreeze continuously learns from each interaction, allowing it to improve its responses and performance over time. We also provide ongoing monitoring and optimization to ensure the AI adapts to new trends, customer needs, and business goals. Your customer service keeps getting better, thanks to VoiceBreeze’s continuous learning."
    },
    {
      question: "What support is available after deployment?",
      answer:"We provide comprehensive support after deployment, including real-time monitoring, troubleshooting, and updates. Our team is always available to ensure VoiceBreeze continues to operate smoothly and effectively."
    },
    {
      question: "How can I get started with VoiceBreeze?",
      answer:"Getting started with VoiceBreeze is simple! Contact us through our website or customer support, and our team will guide you through the setup and integration process. We’ll work closely with you to ensure VoiceBreeze is tailored to your specific needs and ready to transform your customer service operations. Get started today—let VoiceBreeze streamline your customer service and eliminate wait times."
    }

  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
