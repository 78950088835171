import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';

export default function ContactPage() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

const [formData, setFormData] = useState({
    name: '',
    email: '',
    body: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,   

    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!formData.email || !formData.name || !formData.body ){
      alert("Incomplete form! All fields must be completed")
      return 
    }

      const response = await   
      fetch('https://api.swiftsell.shop/api/custom/process_contact_us_form', {
      method: "POST",
      body: JSON.stringify({
      ...formData,
      subject: "Contact us form from "+formData.email
      }),
      headers: {
      "content-type": "application/json"
      }
      });

      const status = response.status;

      if (status != 200){
           alert("Error occured submitting form.")
           return
          }

      const result = await response.json()
      console.log('Form submitted successfully:', result);
      // Handle success, e.g., show a success message, clear the form
      setFormData({
      name: '',
      email: '',
      body: '',
    })
    alert("Form submitted successfully")
   
  };


  return (
    <>
      <PageHeading/>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="We’d love to hear from you!"
              subtitle="Want to learn more? "
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={handleSubmit} className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input required name="name" value={formData.name} onChange={handleChange}  type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input required name="email" value={formData.email} onChange={handleChange}  type="email" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                name="body" value={formData.body} onChange={handleChange} 
                  cols="30"
                  rows="7"
                  required
                  className="cs-form_field"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type="submit" className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
    </>
  );
}
