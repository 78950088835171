import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';

export default function Home() {
  pageTitle('Home');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Automate Report',
      links: '/',
    },
    {
      name: 'Generate PDF',
      links: '/',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Sold Projects',
      factNumber: '+       2K ',
    },
    {
      title: 'Students Satisfaction Rate',
      factNumber: '+90%',
    },
    {
      title: 'Estimated Work Hours',
      factNumber: '+145K',
    },
    {
      title: 'Pages Generated',
      factNumber: '+140K',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Experience the breeze of automated call<br> centers with AI"
        // subtitle="Say goodbye to long wait times and transform your call center operations today!"
        // btnText="MAKE MY REPORT NOW"
        // btnLink="/creative-portfolio"
        scrollDownId="#service"
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      {/* <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun fact"
          subtitle="The automated report generation solution makes report writing easy and fast. By entering data, the system generates reports in seconds, saving users from tedious manual work. The platform is suitable for both students and professionals and meets their reporting needs. The solution offers the convenience of generating reports with just a click of a button."
          data={funfaceData}
        />
      </div> */}
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we provide your business"
                subtitle="Why Choose VoiceBreeze?"
                // btnText="See All Services"
                // btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Seamless Customer Interaction"
                    // link="/service/service-details"
                     src="/images/service_4.jpeg"
                    alt="Seamless Customer Interaction:"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Advanced Conversational Intelligence"
                    // link="/service/service-details"
                    src="/images/service_5.jpeg"
                    alt="Advanced Conversational Intelligence"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Real-Time Reporting and Analytics"
                    //link="/service/service-details"
                     src="/images/photox.jpeg"
                    alt=" Real-Time Reporting and Analytics"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Increased Efficiency and Cost Savings"
                    // link="/service/service-details"
                    src="/images/pdf.jpeg"
                    alt="Increased Efficiency and Cost Savings"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Continuous Learning and Improvement'
                    //link='/service/consultancy'
                    src='/images/service_5.jpeg'
                    alt='Continuous Learning and Improvement'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Easy integration and 24/7 Availability'
                    //link='/service/creative-design'
                    src='/images/service_1.jpeg'
                    alt='Easy integration and 24/7 Availability'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}


      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Say goodbye to long wait times and transform your call center operations today!" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="At VoiceBreeze, we're redefining the way businesses connect with their customers. Our advanced Voice AI for call centers is designed to automate and elevate customer service interactions, ensuring a seamless and efficient experience every time."
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
