import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function CaseStudyDetailsPage() {
  pageTitle('Case Study Details');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title=""
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="GenAI Call Center Agents in Action"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Hear Our GenAI Call Center Agents in Action"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <video  className="w-100 cs-radius_15" controls autoplay>
          <source src="https://res.cloudinary.com/southside-food/video/upload/v1732796470/Project_Voice_Breeze_Final_Final_Version_vpyig8.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Spacing lg="140" md="80" />
       
     
        <Spacing lg="125" md="55" />
      </Div>
      <Spacing lg="145" md="80" />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s discuss and make <br />something <i>cool</i> together"
          btnText="Book a Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
